<template>
  <f-card>
    <v-data-table :headers="headers" :items="items" @click:row="edit">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>&nbsp;</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" large @click="download">
            {{ $t("labels.btnDownload") }}
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </f-card>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("pages.mkt.newsletter.list.email"),
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: this.$t("pages.mkt.newsletter.list.active"),
          align: "start",
          sortable: true,
          value: "active",
        },
        {
          text: this.$t("pages.mkt.newsletter.list.createdAt"),
          value: "created_at",
          align: "end",
        },
      ],
    };
  },
  computed: {
    items() {
      console.log(this.$store.state.mkt.list);
      const list = this.$store.state.mkt.list;
      return list.map((item) => {
        return {
          id: item.id,
          email: item.email,
          active: item.active == 'sim' ? this.$t('labels.yes') : this.$t('labels.no'),
          created_at: item.created_at,
        }
      })
      // return this.$store.state.mkt.list;
    },
    loading() {
      return !this.$store.state.system.loading.done;
    },
  },
  methods: {
    edit(news) {
      this.$router.push({
        name: "mkt-newsletter-view",
        params: {
          id: news.id,
        },
      });
    },
    async download() {
      const data = this.items.map((item) => {
        return {
          ID: item.id,
          email: item.email,
          active: item.active == 'sim' ? this.$t('labels.yes') : this.$t('labels.no'),
        };
      });

      const filename = `newsletterEmailList_${moment().format(
        "YYYYMMDD_HHmmss"
      )}.xlsx`;
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet");

      XLSX.writeFile(wb, filename);
    },
  },
};
</script>
